import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { PreventoColors } from '../../styles/globalStyles';
import { companyData } from '../../const/environment';

const HeaderWrapper = styled.header`
  width: 100%;
  background: rgba(31, 60, 96, 0.95);
  backdrop-filter: blur(8px);
  position: fixed;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

const HeaderContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.8rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  font-size: 1.8rem;
  font-weight: 800;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
  z-index: 1001;

  &:hover {
    transform: translateY(-2px);
  }

  span {
    background: linear-gradient(135deg, ${PreventoColors.standardPrevento.secondary} 0%, #7FFFD4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 3px;
  }

  @media (min-width: 768px) {
    font-size: 2.2rem;
  }
`;

const Navigation = styled.nav`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    gap: 3rem;
  }
`;

const MobileNavigation = styled.nav`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  width: 75%;
  height: 100vh;
  background: rgba(31, 60, 96, 0.98);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  transition: left 0.3s ease-in-out;
  box-shadow: ${({ isOpen }) => (isOpen ? '5px 0 15px rgba(0, 0, 0, 0.2)' : 'none')};
  z-index: 1000;
  padding: 2rem;

  @media (min-width: 768px) {
    display: none;
  }
`;

const NavLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
  position: relative;
  padding: 0.5rem 0;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: ${PreventoColors.standardPrevento.secondary};
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100%;
  }

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const CTAButton = styled.button`
  background: transparent;
  color: ${PreventoColors.standardPrevento.secondary};
  border: 2px solid ${PreventoColors.standardPrevento.secondary};
  padding: 0.7rem 1.3rem;
  border-radius: 50px;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  min-width: 140px;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: ${PreventoColors.standardPrevento.secondary};
    transition: all 0.3s ease;
    z-index: -1;
  }

  &:hover {
    color: ${PreventoColors.standardPrevento.primary};
    &::before {
      left: 0;
    }
  }

  @media (min-width: 768px) {
    padding: 0.8rem 1.8rem;
    font-size: 1rem;
  }
`;

const HamburgerButton = styled.button`
  display: flex;
  flex-direction: column;
  gap: 6px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  z-index: 1001;

  @media (min-width: 768px) {
    display: none;
  }

  div {
    width: 25px;
    height: 2px;
    background: white;
    border-radius: 2px;
    transition: all 0.3s ease;

    &:nth-child(1) {
      transform: ${({ isOpen }) => isOpen ? 'rotate(45deg) translate(6px, 6px)' : 'none'};
    }

    &:nth-child(2) {
      opacity: ${({ isOpen }) => isOpen ? '0' : '1'};
      transform: ${({ isOpen }) => isOpen ? 'translateX(-20px)' : 'none'};
    }

    &:nth-child(3) {
      transform: ${({ isOpen }) => isOpen ? 'rotate(-45deg) translate(6px, -6px)' : 'none'};
    }
  }
`;

const Overlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;

  @media (min-width: 768px) {
    display: none;
  }
`;

function PreventoHeader() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  const handleLogoClick = () => {
    const isProduction = window.location.hostname === 'prevento.codedavinci.com';
    if (isProduction) {
      window.location.href = 'https://prevento.codedavinci.com';
    } else {
      navigate('/prevento');
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    closeMenu();
  };

  const handleDemoClick = () => {
    const message = encodeURIComponent(
      "Ciao! Sono interessato a una demo di Prevento. Vorrei saperne di più sul vostro gestionale per aziende."
    );
    window.open(`https://wa.me/${companyData.whatsapp}?text=${message}`, '_blank');
    closeMenu();
  };

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Logo onClick={handleLogoClick}>
          Prevento<span>.</span>
        </Logo>

        <Navigation>
          <NavLink href="#features">Caratteristiche</NavLink>
          <NavLink href="#portfolio">Portfolio</NavLink>
          <NavLink href="#testimonials">Testimonianze</NavLink>
          <NavLink href="#faq">FAQ</NavLink>
          <CTAButton onClick={handleDemoClick}>Richiedi Demo</CTAButton>
        </Navigation>

        <HamburgerButton onClick={toggleMenu} isOpen={isMenuOpen}>
          <div />
          <div />
          <div />
        </HamburgerButton>

        <Overlay isOpen={isMenuOpen} onClick={closeMenu} />
        
        <MobileNavigation isOpen={isMenuOpen}>
          <NavLink href="#features" onClick={closeMenu}>Caratteristiche</NavLink>
          <NavLink href="#portfolio" onClick={closeMenu}>Portfolio</NavLink>
          <NavLink href="#testimonials" onClick={closeMenu}>Testimonianze</NavLink>
          <NavLink href="#faq" onClick={closeMenu}>FAQ</NavLink>
          <CTAButton onClick={handleDemoClick}>Richiedi Demo</CTAButton>
        </MobileNavigation>
      </HeaderContainer>
    </HeaderWrapper>
  );
}

export default PreventoHeader; 