import React, { useEffect } from 'react';
import styled from 'styled-components';
import { brandColor } from '../styles/globalStyles';
import { companyData } from '../const/environment';
import { Link } from 'react-router-dom';

const FooterWrapper = styled.footer`
  background-color: ${brandColor};
  color: white;
  padding: 40px 0;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
    z-index: 1;
  }
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterSection = styled.div`
  flex: 1;
  margin: 0 20px;

  @media (max-width: 768px) {
    margin: 20px 0;
    text-align: center;
  }
`;

const FooterTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.9);
`;

const FooterLink = styled.a`
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
  transition: color 0.3s ease;

  &:hover {
    color: white;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const SocialIcon = styled.a`
  color: white;
  margin-right: 15px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-3px);
  }

  img {
    width: 24px;
    height: 24px;
    filter: invert(1); // This will make the black SVG appear white
  }
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9rem;
  position: relative;
  z-index: 2;
`;

const TrustpilotWidget = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  position: relative;
`;

const TrustpilotLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const StyledLink = styled(Link)`
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
  transition: color 0.3s ease;

  &:hover {
    color: white;
  }
`;

const Footer = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://invitejs.trustpilot.com/tp.min.js';
    script.async = true;
    script.type = 'text/javascript';
    script.onload = () => {
      if (window.tp) {
        window.tp('register', 'huLz9NLUyAOUVdJP');
        const trustpilot_invitation = {
          recipientEmail: 'john@gmail.com',
          recipientName: 'John',
          referenceId: 'Order_123',
          source: 'InvitationScript',
        };
        window.tp('createInvitation', trustpilot_invitation);
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <FooterWrapper>
      <FooterContent>
        <FooterSection>
          <FooterTitle>Chi siamo</FooterTitle>
          <FooterLink href={companyData.websiteLink.v_foti}>La nostra storia</FooterLink>
          {/* <FooterLink href="/team">Our Team</FooterLink>
          <FooterLink href="/careers">Careers</FooterLink> */}
        </FooterSection>
        <FooterSection>
          <FooterTitle>Servizi</FooterTitle>
          <StyledLink to="/sales">Sviluppo Web</StyledLink>
          <StyledLink to="/sales">Sviluppo App Mobile</StyledLink>
          <StyledLink to="/sales">Social Media Manager</StyledLink>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Contatti</FooterTitle>
          <FooterLink href={`mailto:${companyData.email}`}>{companyData.email}</FooterLink>
          <FooterLink href={`tel:${companyData.tel}`}>{companyData.tel_label}</FooterLink>
          <SocialIcons>
            <SocialIcon href={companyData.socialLinks.linkedin} target="_blank" rel="noopener noreferrer">
              <img src="/assets/svg/linkedin.svg" alt="LinkedIn" />
            </SocialIcon>
            <SocialIcon href={companyData.socialLinks.instagram} target="_blank" rel="noopener noreferrer">
              <img src="/assets/svg/instagram.svg" alt="Instagram" />
            </SocialIcon>
            <SocialIcon href={companyData.socialLinks.threads} target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24" fill="currentColor">
                <path d="M331.5 235.7c2.2 .9 4.2 1.9 6.3 2.8c29.2 14.1 50.6 35.2 61.8 61.4c15.7 36.5 17.2 95.8-30.3 143.2c-36.2 36.2-80.3 52.5-142.6 53h-.3c-70.2-.5-124.1-24.1-160.4-70.2c-32.3-41-48.9-98.1-49.5-169.6V256v-.2C17 184.3 33.6 127.2 65.9 86.2C102.2 40.1 156.2 16.5 226.4 16h.3c70.3 .5 124.9 24 162.3 69.9c18.4 22.7 32 50 40.6 81.7l-40.4 10.8c-7.1-25.8-17.8-47.8-32.2-65.4c-29.2-35.8-73-54.2-130.5-54.6c-57 .5-100.1 18.8-128.2 54.4C72.1 146.1 58.5 194.3 58 256c.5 61.7 14.1 109.9 40.3 143.3c28 35.6 71.2 53.9 128.2 54.4c51.4-.4 85.4-12.6 113.7-40.9c32.3-32.2 31.7-71.8 21.4-95.9c-6.1-14.2-17.1-26-31.9-34.9c-3.7 26.9-11.8 48.3-24.7 64.8c-17.1 21.8-41.4 33.6-72.7 35.3c-23.6 1.3-46.3-4.4-63.9-16c-20.8-13.8-33-34.8-34.3-59.3c-2.5-48.3 35.7-83 95.2-86.4c21.1-1.2 40.9-.3 59.2 2.8c-2.4-14.8-7.3-26.6-14.6-35.2c-10-11.7-25.6-17.7-46.2-17.8H227c-16.6 0-39 4.6-53.3 26.3l-34.4-23.6c19.2-29.1 50.3-45.1 87.8-45.1h.8c62.6 .4 99.9 39.5 103.7 107.7l-.2 .2zm-156 68.8c1.3 25.1 28.4 36.8 54.6 35.3c25.6-1.4 54.6-11.4 59.5-73.2c-13.2-2.9-27.8-4.4-43.4-4.4c-4.8 0-9.6 .1-14.4 .4c-42.9 2.4-57.2 23.2-56.2 41.8l-.1 .1z"/>
              </svg>
            </SocialIcon>
            <SocialIcon href={companyData.socialLinks.twitter} target="_blank" rel="noopener noreferrer">
              <img src="/assets/svg/twitterx.svg" alt="Twitter X" />
            </SocialIcon>
          </SocialIcons>
        </FooterSection>
      </FooterContent>
      <TrustpilotWidget>
        <TrustpilotLink 
          href={companyData.trustpilot} 
          target="_blank" 
          rel="noopener noreferrer"
        />
        {/* TrustBox widget - Review Collector */}
        <div className="trustpilot-widget" data-locale="it-IT" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="66f50fc5f3776e76986a3039" data-style-height="52px" data-style-width="100%">
          <a href={companyData.trustpilot} target="_blank" rel="noopener">Trustpilot</a>
        </div>
        {/* End TrustBox widget */}
      </TrustpilotWidget>
      <Copyright>
        © {new Date().getFullYear()} CodeDaVinci - P.IVA: {companyData.piva}
      </Copyright>
    </FooterWrapper>
  );
};

export default Footer;
